<template>
  <div class="box">
    <img src="@/assets/img/404.png" alt="">
    <p>对不起  没有查询到您的账号</p>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
.box {
  height: 100vh;
  img {
    width: 100%;
    margin-top: 200px;
  }
  p {
    text-align: center;
    font-size: 40px;
    color: #387FF5;
  }
}
</style>